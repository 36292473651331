.item-page-content {
  height: 21.25rem;
  overflow-y: auto;
  // overflow-y: scroll;
  flex: auto !important;

  .opacity {
    opacity: 0.3;
  }
}

.item-page-btn-box {
  margin-top: 0.7rem !important;
}

.div-search-box {
  padding-top: 0.5rem;
  padding-bottom: 0.6rem;
  max-width: 18.75rem;
  width: 100%;
  min-height: 4rem;
  background-color: rgba(138, 138, 138, 0.2);
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > p:nth-of-type(1) {
    font-size: 0.688rem;
    color: #666666;
    font-weight: 800;
  }

  > p:nth-of-type(2) {
    color: #141212;
    font-size: 1.125rem;
    font-weight: bold;
    margin-top: 0.2rem;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.exception-description {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  height: 100%;
  color: #666;
  text-align: center;
  word-break: keep-all;
}

.product-excess {
  text-align: start !important;
  height: 4rem !important;
  margin-bottom: 1rem;
}
