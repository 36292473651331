@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap");

* {
  outline: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  margin: 0;
  font-family: "Noto Sans KR";
  min-height: 35.25rem;
}

html,
#root {
  height: 100%;
}

body {
  height: calc(100% - 80px);
}

.h-auto {
  height: auto !important;
}

input {
  border: none;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.btn-box {
  margin-top: 1.875rem;
  display: flex;
  justify-content: space-between;
  max-width: 18.75rem;
  width: 100%;
  height: 3.95rem;
  > div {
    flex: 1;
    margin: 0.1rem;
    font-size: 1.25rem;
    letter-spacing: -0.02em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
  > div:nth-of-type(2) {
    margin-left: 1rem;
  }
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track-piece {
  border-radius: 2px;
  background-color: rgba(138, 138, 138, 0.2);
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(20, 20, 20, 0.8);
}
