#app-version {
  position: relative;
  top: 40px;
  font-size: 11px;
  color: #838383;
  span {
    font-weight: bold;
  }
}

#root {
  background-color: #f9f9f9;
}

// -- web - (create backdrop) -----------------------------
@media screen and (min-width: 769px) {
  .app-container {
    background-color: #e5e5e5;
    height: 100% !important;
    > div {
      background-color: #f9f9f9;
      border-radius: 0.25rem;
    }
  }
}

// -- mobile ----------------------------------------------
@media screen and (max-width: 400px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-width: 375px) {
  html {
    font-size: 14.5px;
  }
}

@media screen and (max-width: 350px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 335px) {
  html {
    font-size: 13.5px;
  }
}

@media screen and (max-width: 325px) {
  html {
    font-size: 13px;
  }
}

@media screen and (max-width: 300px) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-height: 740px) {
  #root {
    height: auto !important;
  }
  body {
    background-color: #f9f9f9;
  }
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.common-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 25rem;
  width: 100%;
  min-height: 35.25rem;
  height: auto;
  padding: 3.125rem;
}

.common-title {
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
}

.common-description {
  text-align: center;
  color: #666666;
  font-size: 0.875rem;
  letter-spacing: -0.025em;
  line-height: 1.375rem;
  white-space: break-spaces;
  word-break: keep-all;
}

.common-content {
  flex: 1;
  width: 100%;
  max-width: 18.75rem;
  margin-top: 1.25rem;
}

.content-margin {
  margin-top: 2.5rem;
}
