.search-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  cursor: pointer;
  .image-box {
    border: solid 1px #d1d1d1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 4.37rem;
    height: 4.37rem;
    flex: none;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .content-box {
    padding-left: 0.625rem;
    height: 4.375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 14.063rem;
    > p {
      line-height: 1.27rem;
      width: 12.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-weight: 700;
    }
    > div {
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
        img {
          width: 1.031rem;
          height: 1.031rem;
        }
        > span {
          font-size: 1rem;
          padding-left: 0.375rem;
          padding-bottom: 0.125rem;
        }
      }
      > div:last-of-type {
        margin-right: 1.25rem;
      }
    }
  }
}
