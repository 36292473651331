.img-box {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  .img {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    border-radius: 0.5rem;
  }
}

.request-item-name {
  font-size: 12px;
  color: #666666;
  text-align: center;
  font-weight: 700;
}
