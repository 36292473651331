.mainBtn {
  background-color: #d03739;
  color: white;
  font-weight: 500;
}

.subBtn {
  background-color: rgba(138, 138, 138, 0.2);
  color: #141212;
  font-weight: 700;
}

[disabled] {
  background-color: rgba(138, 138, 138, 0.2);
  color: white;
  cursor: default !important;
}
