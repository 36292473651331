.item-box {
  margin-top: 1.25rem;
  height: 19.5rem;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  .item-img {
    position: relative;
    margin-left: 3.5%;
    width: 31%;
    height: 48%;
    > img {
      width: 100%;
      height: 100%;
      border: solid 1px #ddd;
      border-radius: 0.25rem;
      cursor: pointer;
      object-fit: cover;
    }

    > div:nth-of-type(1) {
      z-index: 1;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      border-radius: 0.25rem;
      background: linear-gradient(
        180deg,
        rgba(20, 18, 18, 0) 53.65%,
        rgba(20, 18, 18, 0.352) 80.73%,
        rgba(20, 18, 18, 0.704) 100%
      );

      > p {
        color: #adadad;
        font-size: 1rem;
        font-weight: 500;
        text-decoration: line-through;
      }
      > div {
        display: flex;
        align-items: center;
        margin-bottom: 0.625rem;
        > img {
          margin-right: 0.625rem;
        }
        > span {
          font-size: 1rem;
          font-weight: 500;
          color: #f9f9f9;
        }
      }
    }
    > .click-point {
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 2;
      position: absolute;
    }
    .opacity {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
  > .item-img:nth-of-type(3n-2) {
    margin-left: 0rem;
  }
}

.h-auto {
  .item-img {
    height: 9.5rem !important;
  }
}

.unopened-porduct-box {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1.063rem;
  > p {
    font-size: 0.75rem;
    letter-spacing: -0.5px;
    color: #5c5c5c;
  }
  > a {
    color: #4285f4;
    font-size: 0.75rem;
    text-decoration: none;
    border-bottom: solid 1px #4285f4;
    margin-left: 0.375rem;
    cursor: pointer;
  }
}
