.my-shop-link {
  font-size: 0.75rem;
  color: #838383;
  text-align: center;
  > a {
    color: #4285f4;
    cursor: pointer;
    text-decoration: auto;
  }
}

.search-box {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
  border-radius: 4px;
  max-width: 18.75rem;
  width: 100%;
  height: 4rem;
  color: #666666;
  background-color: rgba(138, 138, 138, 0.2);
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: solid 2px transparent;

  label {
    font-weight: 700;
    font-size: 0.703rem;
  }

  input {
    display: none;
    color: #211e1f;
    font-size: 1.125rem;
    font-weight: 700;
    padding-top: 0.2rem;
    margin-bottom: 0.438rem;
    background-color: inherit;
  }
}

.active {
  padding: 0 0.5rem;
  border: solid 2px #000;
  background: inherit;
  input {
    display: block;
    border: none;
  }
}
